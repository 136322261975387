import { useState } from "react";
import { useWallet } from "../contexts/WalletProvider";

const shortenAddress = (address: string) => {
  const prefix = address.slice(0, 6);
  const suffix = address.slice(-4);
  return `${prefix}...${suffix}`;
};

export function WalletConnector() {
  const { activated, account, connect, disconnect } = useWallet();
  const [hover, setHover] = useState(false);


  const onConnect = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    await connect();
  }

  const onDisconnect = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    await disconnect();
  }

  const account_connected = (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onDisconnect}
      className="cursor-pointer"
      id="wallet-address"
    >
        <div className={"w-28 py-2 text-center" + (hover ? " border border-base_border_dark" : "")}>
            {hover ? "Disconnect" : account ? shortenAddress(account as string) : "..."}
        </div>
    </div>
  );

  const account_disconnected = (
    <button
      onClick={onConnect}
      className="w-28 py-2 px-4 rounded"
    >
      Connect
    </button>
  );

  return (
    <div className="ml-8 mr-4 mt-2 flex justify-end items-center h-10 rounded-md">
      <div>{activated ? account_connected : account_disconnected}</div>
    </div>
  );
}
