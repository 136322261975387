import toast, { Toast } from "react-hot-toast";

interface TransactionProcessingToastProps {
    t: Toast,
    txHash: string
    }

export const TransactionProcessingToast = ({ t, txHash }: TransactionProcessingToastProps) => {
    const polygonScanLink = `https://polygonscan.com/tx/${txHash}`;

    return <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-md w-full ml-6`}
    >
      <div className="mb-2">Transaction processing.</div>
      <div>
        <a target="_blank" href={polygonScanLink} rel="noreferrer" className="underline" onClick={() => toast.dismiss(t.id)}>
          Check on PolygonScan↗
        </a>
      </div>
    </div>
    }