import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import { WalletConnector } from './WalletConnector';
import { useWallet } from '../contexts/WalletProvider';
import MerkleTree from "merkletreejs";
import { ethers } from "ethers";

import BabyGrindBear_ABI from "../contract/BabyGrindBear.json";
import contractInfo from "../contract/contract-info.json";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { TransactionProcessingToast } from './TransactionProcessingToast';

const WHITELIST_URL = "https://gist.githubusercontent.com/Thyrst/4d981be8a233f1b8c5dcf9951bee2909/raw/baby_grind_bears_whitelist.txt";
const PUBLIC_MINT_PRICE = 5;
const MAX_SUPPLY = 400;

const GAS_PRICE = 0.01

const fetchWhitelist = async (url: string) => {
  const response = await axios({
    method: "get",
    url: WHITELIST_URL
  });
  const text = await response.data;

  const addresses = text
    .split("\n")
    .map((line: string) => line.trim().toLowerCase())
    .filter((line: string) => line.length > 0);
  return addresses;
};

const getProof = (whitelist: string[], account: string) => {
  const accountIndex = whitelist.indexOf(account.toLowerCase());
  if (accountIndex === -1) {
    return null;
  }

  const leaves = whitelist.map((address) => ethers.keccak256(address));
  const merkleTree = new MerkleTree(leaves, ethers.keccak256, { sortPairs: true });

  const proof = merkleTree.getHexProof(leaves[accountIndex]);
  return proof;
};

function App() {
  const { signer, account, balance, activated, error } = useWallet();
  const [quantity, setQuantity] = useState(1);
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [whitelist, setWhitelist] = useState<string[]>([]);
  const [proof, setProof] = useState<string[] | null>(null);
  const [accountWhiteListMinted, setAccountWhiteListMinted] = useState(false);
  const [mintedCount, setMintedCount] = useState(0);
  const [isPublicMintLoading, setIsPublicMintLoading] = useState(false);
  const [isWhiteListMintLoading, setIsWhiteListMintLoading] = useState(false);
  const accountWhiteListed = proof !== null;

  const supplyLeft = MAX_SUPPLY - mintedCount;
  const mintedOut = supplyLeft <= 0;

  const connectedFine = activated && !error;

  const publicMintPrice = PUBLIC_MINT_PRICE * quantity;
  const publicMintPriceValue = ethers.parseEther(publicMintPrice.toString());

  let publicMintText = "Mint"
  let whiteListMintText = "Free Mint"
  let whiteListDescription = (
  <span>
    <span>Get your whitelist by filling </span>
    <a href="https://forms.gle/XFAn4cDVRTUJ8HAc6" target="_blank" rel="noopener noreferrer" className="underline">
      a&nbsp;short&nbsp;survey!
    </a>
  </span>
  )
  const publicMintEnabled = connectedFine && !mintedOut;
  const whiteListMintEnabled = connectedFine && accountWhiteListed && !accountWhiteListMinted;


  const showTxToast = (txHash: string) => {
    toast.success((t) => <TransactionProcessingToast t={t} txHash={txHash} />,
    {
      duration: 4000,
    })
  }

  const showErrorToast = (message: string) => {
    toast.error(message,
      {
        duration: 2000,
        iconTheme: {
          primary: '#ff0000',
          secondary: 'rgb(26, 32, 44)',
        }
      }
      );
  }


  if (accountWhiteListMinted) {
    whiteListDescription = <span>You already minted.</span>
  } else if (accountWhiteListed) {
    whiteListDescription = <span>Your wallet is whitelisted!</span>
  }

  if (!activated) {
    publicMintText = "Wallet not connected"
    whiteListMintText = "Wallet not connected"
    whiteListDescription = <span>-</span>
  } else if (mintedOut) {
    publicMintText = "Sold out"
    whiteListMintText = "Sold out"
    whiteListDescription = <span>-</span>
  }

  if (isPublicMintLoading) {
    publicMintText = "Processing..."
  }
  if (isWhiteListMintLoading) {
    whiteListMintText = "Processing..."
  }

  const handleIncrement = () => {
    if (quantity < supplyLeft) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleInputChange = (event: { target: { value: any; }; }) => {
    setQuantity(Number(event.target.value));
  };

  const handleTransfer = useCallback(
    (from: string, to: string, tokenId: ethers.BigNumberish): void => {
      if (from === ethers.ZeroAddress) {
        if (contract) {
          setMintedCount(mintedCount + 1);
        }
      }
    },
    [contract, mintedCount]
  );

  useEffect(() => {
    fetchWhitelist(WHITELIST_URL).then(setWhitelist);
  }, []);

  useEffect(() => {
    if (signer && account && !error) {
      setAccountWhiteListMinted(false);
      const proof = getProof(whitelist, account);
      setProof(proof);
      const contract = new ethers.Contract(contractInfo.address, BabyGrindBear_ABI.abi, signer);
      setContract(contract);
    } else {
      setProof(null);
      setContract(null);
    }
  }, [signer, account, whitelist, error]);

  const handleContractError = useCallback((e: Error | string | any) => {
      console.error(e)
      if (e.data && contract) {
        const decodedError = contract.interface.parseError(e.data);
        console.error(`Transaction failed: ${decodedError ? (decodedError.name + ": " + decodedError.args) : e}`);
      }
    }, [contract]);

  useEffect(() => {
    if (contract && connectedFine) {
      contract.totalSupply().then((supply: bigint) => {
        setMintedCount(Number(supply));
      }).catch(handleContractError);
      if (!accountWhiteListMinted) {
        contract.whiteListMinted(account).then((minted: boolean) => {
          setAccountWhiteListMinted(minted);
        }).catch(handleContractError);
      }
      contract.on("Transfer", handleTransfer);
      return () => {
        contract.off("Transfer", handleTransfer);
      };
    }
  }, [contract, account, connectedFine, handleTransfer, handleContractError, accountWhiteListMinted]);


  const handleMintError = useCallback((e: Error | string | any, whitelist: boolean) => {
    if (e.message && e.message.startsWith("user rejected action")) {
      console.log("User rejected transaction");
    } else {
      const _balance = Number(balance || 0);
      let fundsNeeded = GAS_PRICE;
      if (!whitelist) {
        fundsNeeded += Number(publicMintPriceValue);
      }
      if (e.action === "estimateGas" && _balance < fundsNeeded) {
        showErrorToast("Not enough funds");
      } else {
        handleContractError(e);
        showErrorToast("Transaction failed");
      }
    }
  }, [balance, publicMintPriceValue, handleContractError]);


  const whiteListMint = async () => {
    if (contract && whiteListMintEnabled) {
      setIsWhiteListMintLoading(true);
      try {
        const result: ethers.TransactionResponse = await contract.whiteListMint(proof);
        setAccountWhiteListMinted(true);
        showTxToast(result.hash);
      } catch (e: any) {
        handleMintError(e, true);
      } finally {
        setIsWhiteListMintLoading(false);
      }
    } else {
      showErrorToast("Not available");
    }
  };

  const publicMint = async () => {
    if (contract && publicMintEnabled) {
      setIsPublicMintLoading(true);
      try {
        const result: ethers.TransactionResponse = await contract.publicMint(quantity, { value: publicMintPriceValue });
        showTxToast(result.hash);
      } catch (e: any) {
        handleMintError(e, false);
      } finally {
        setIsPublicMintLoading(false);
      }
    } else {
      showErrorToast("Not available");
    }
  };

  let errorMessage: string | null = null;
  if (error) {
    if (error.message.startsWith("Unsupported chain id:")) {
      errorMessage = "Please switch to Polygon network";
    } else {
      errorMessage = error.message;
    }
  }

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-base_bg_light to-base_bg py-2">
      <header>
      <div className="flex flex-row justify-end">
      <div className="mt-2 hidden sm:flex justify-end items-center h-10 border-r-2 border-base_border_dark">
        <div className="px-8">
          <a target="_blank" href="https://magiceden.io/collections/polygon/0xea2ca8fa9a1887e4d83e61fb39632593f89709d6" rel="noreferrer">
            <span>Magic Eden</span>
        </a>
        </div>
        </div>
      <div className="mt-2 flex justify-end items-center h-10 border-r-2 border-base_border_dark">
        <div className="px-8">
          <a target="_blank" href="https://discord.gg/x5ACsv3Z6U" rel="noreferrer">Join Discord!</a>
        </div>
        </div>
        <WalletConnector />
      </div>
      {error && (
        <div className="flex justify-end text-center text-red-500 font-bold mx-4 mt-6 mb-2 xl:mb-0">
          <span>Error!&nbsp; {errorMessage}</span>
        </div>
      )}
      </header>
    <main className='flex-grow'>
      <div className="flex flex-col items-center space-y-10 lg:space-y-12 mt-6 xl:mt-0">
        <h1 className="text-4xl font-bold">Baby Grind Bears</h1>
        <div className="mb-20 mx-8 text-l text-center">{mintedCount}/{MAX_SUPPLY} minted</div>
      </div>
      <div className="mt-14 lg:mt-16">
        <div className="flex flex-col md:flex-row items-center justify-center space-y-14 md:space-y-0 md:space-x-14 lg:space-x-24">

        <div className="bg-base_card p-10 rounded-xl shadow-lg md:w-1/4 min-w-80 w-80 max-w-80">
          <div className="aspect-w-1 aspect-h-1 object-center">
            <img className="object-cover rounded shadow w-full" alt="Public Mint" src="/public.png" />
          </div>
          <div className="h-10 flex items-center justify-between my-8">
            <div className="flex items-center space-x-2">
              <button className="px-4 py-2 rounded" onClick={handleDecrement}>-</button>
              <input className="text-center h-10 w-10 border rounded" type="number" value={quantity} min="1" onChange={handleInputChange}/>
              <button className="px-4 py-2 rounded" onClick={handleIncrement}>+</button>
            </div>
            <div className="text-lg">{quantity * PUBLIC_MINT_PRICE} MATIC</div>
          </div>
          <button className="w-full px-4 py-2 rounded" onClick={publicMint} disabled={!publicMintEnabled}>{publicMintText}</button>
        </div>


        <div className="bg-base_card p-10 rounded-xl shadow-lg md:w-1/4 min-w-80 w-80 max-w-80">
          <div className="aspect-w-1 aspect-h-1 object-center">
            <img className="object-cover rounded shadow w-full" alt="Whitelist Mint" src="/whitelist.png" />
          </div>
          <div className="flex items-center h-10 justify-center text-center text-lg my-8">{whiteListDescription}</div>
          <button className="w-full px-4 py-2 rounded" onClick={whiteListMint} disabled={!whiteListMintEnabled}>{whiteListMintText}</button>
        </div>

        </div>
      </div>
    </main>
    <footer className="mt-12 text-center opacity-50 text-sm">
      <hr />
      <div className="m-5">Copyright © 2024 GrindBook</div>
    </footer>
    <Toaster
      toastOptions={{
        style: {
          color: 'rgb(190, 227, 248)',
          backgroundColor: 'rgb(42, 55, 73)',
          border: '1px solid rgb(144, 205, 244)',
          boxShadow: '0 0 10px rgb(26, 32, 44)',
          padding: '16px 32px',
        },
        iconTheme: {
          primary: 'rgb(144, 205, 244)',
          secondary: 'rgb(26, 32, 44)',
        }
      }}
    />
    </div>
  );
}

export default App;
