import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import contractInfo from "../contract/contract-info.json";


const POLYGON_CHAIN_ID = BigInt(contractInfo.chainId)

interface WalletContextValue {
  provider: ethers.BrowserProvider | null
  signer: ethers.Signer | null
  activated: boolean
  error: Error | null
  account: string | null
  balance: bigint | null
  connect: Function
  disconnect: Function
}

const WalletContext = createContext<WalletContextValue>({
  provider: null,
  signer: null,
  activated: false,
  account: null,
  error: null,
  balance: null,
  connect: () => {},
  disconnect: () => {}
})

export const useWallet = (): WalletContextValue => {
  return useContext(WalletContext)
}

export interface WalletProviderProps {
  children: React.ReactNode
}

export const WalletProvider = ({
  children
}: WalletProviderProps): JSX.Element => {
  const [connected, setConnected] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("connected")
    return storedValue === "true"
  })
  const [error, setError] = useState<Error | null>(null)
  const [signer, setSigner] = useState<ethers.Signer | null>(null)
  const [provider, setProvider] = useState<ethers.BrowserProvider | null>(null)
  const [account, setAccount] = useState<string | null>(null)
  const [balance, setBalance] = useState<bigint | null>(null)

  const injectedProvider = window.ethereum || null;

  useEffect(() => {
    localStorage.setItem("connected", connected.toString())
  }, [connected])

  const handleError = useCallback((e: Error | string | unknown) => {
    console.error(e)
    if (e instanceof Error) {
      setError(e)
    } else if (typeof e === "string") {
      setError(Error(e))
    } else {
      setError(Error("Unknown error"))
    }
  }, [])

  const resetWalletData = useCallback(() => {
    setSigner(null)
    setAccount(null)
    setBalance(null)
  }, [])

  const deactivate = useCallback(() => {
    resetWalletData()
    setConnected(false);
  }, [resetWalletData])

  const activate = useCallback(async () => {
    if (provider == null) {
      throw new Error("Metamask not detected")
    }

    const signer_ = await provider.getSigner();
    setSigner(signer_)

    const walletAddress = await signer_.getAddress()
    setAccount(walletAddress)

    const balance_ = await provider.getBalance(walletAddress)
    setBalance(balance_)

    setConnected(true);
  }, [provider])

  const loadProvider = useCallback(async () => {
    resetWalletData()
    setError(null)

    // Connect to the MetaMask EIP-1193 object. This is a standard
    // protocol that allows Ethers access to make all read-only
    // requests through MetaMask.
    const provider_ = new ethers.BrowserProvider(injectedProvider)
    setProvider(provider_)

    const network = await provider_.getNetwork()
    if (network.chainId !== POLYGON_CHAIN_ID) {
      setError(Error("Unsupported chain id: " + network.chainId))
    }
  }, [resetWalletData, injectedProvider])

  useEffect(() => {
    if (injectedProvider) {
      if (injectedProvider.isPhantom) {
        setError(Error("Phantom wallet not supported"))
        setConnected(false)
      } else if (injectedProvider.isMetaMask) {
        loadProvider()
        injectedProvider.on('chainChanged', (chainId: string) => {
          loadProvider()
        });
      } else {
        setError(Error("Unsupported wallet"))
        setConnected(false)
      }
    } else {
      setError(Error("MetaMask not installed"))
      setConnected(false)
    }
    return () => {
      if (injectedProvider) {
        injectedProvider.removeAllListeners('chainChanged')
      }
    }
  }, [loadProvider, injectedProvider])

  useEffect(() => {
    if (injectedProvider) {
      injectedProvider.on('accountsChanged', (newAccounts: String[]) => {
        // "newAccounts" will always be an array, but it can be empty.
        if (provider && connected && newAccounts.length > 0) {
          activate()
        } else {
          // deactivate if the user disconnected their last account
          deactivate()
        }
      })
    }
    return () => {
      if (injectedProvider) {
        injectedProvider.removeAllListeners('accountsChanged')
      }
    }
  }, [activate, deactivate, provider, connected, injectedProvider])

  const connect = useCallback(async () => {
    try {
      await activate();
    } catch (e) {
      handleError(e);
    }
  }, [activate, handleError]);

  const disconnect = useCallback(async () => {
    try {
      deactivate();
    } catch (e) {
      handleError(e);
    }
  }, [deactivate, handleError]);

  useEffect(() => {
    if (provider && connected) {
      connect()
    }
  }, [provider, connected, account, connect])

  return (
    <WalletContext.Provider
      value={{ provider, signer, activated: account !== null, account, error, balance, connect, disconnect }}
    >
      {children}
    </WalletContext.Provider>
  )
}
